@mixin flexbox($justify: center, $align: center, $direction: row, $wrap: nowrap, $inline: null) {

	@if $inline == null {
		display: flex;
	} @else {
		display: inline-flex;
	}

	align-items: $align;
	flex-direction: $direction;
	flex-wrap: $wrap;
	justify-content: $justify;

}