@import '../../styles/variables';

.ruler {

	position: absolute;

	span {
		color: black;
		display: block;
		font-size: 12px;
		height: 18px;
		margin-left: -50px; // -(width / 2)
		position: absolute;
		text-align: center;
		top: 1px;
		white-space: nowrap;
		width: 100px;
	}

	&::after {
		background-color: black;
		content: '';
		height: 30px;
		position: absolute;
		width: 1px;
	}

	// horizontal
	&__top, &__bottom {
		height: $ruler-width;
		left: $ruler-width;
		width: calc(100vw - #{$ruler-width * 2});
	}

	// vertical
	&__left, &__right {
		height: calc(100vh - #{$ruler-width * 2});
		top: $ruler-width;
		width: $ruler-width;

		span {
			left: 10px;
			margin-top: -9px;
			transform: rotate(90deg);
			transform-origin: 50px 9px;
		}
	}

	&__top {
		border-bottom: $ruler-border;
		clip-path: polygon(-20px 0, calc(100% + 20px) 0, 100% 100%, 0 100%);
		top: 0;

		&::after {
			bottom: 0;
			left: -1px;
			transform: rotate(-45deg);
			transform-origin: bottom right;
		}
	}

	&__right {
		border-left: $ruler-border;
		clip-path: polygon(0 0, 100% -20px, calc(100% + 20px) 100%, 0 100%);
		right: 0;

		&::after {
			left: -1px;
			top: -30px;
			transform: rotate(45deg);
			transform-origin: bottom left;
		}
	}

	&__bottom {
		border-top: $ruler-border;
		bottom: 0;
		clip-path: polygon(0 0, 100% 0, calc(100% + 20px) 100%, -20px 100%);

		&::after {
			right: -1px;
			top: 0;
			transform: rotate(-45deg);
			transform-origin: left top;
		}
	}

	&__left {
		border-right: $ruler-border;
		clip-path: polygon(0 -20px, 100% 0, 100% 100%, 0 calc(100% + 20px));
		left: 0;

		&::after {
			bottom: -30px;
			right: -1px;
			transform: rotate(45deg);
			transform-origin: top right;
		}
	}

}