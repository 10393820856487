@import '../../styles/variables';

.earthMap {
	height: 100vh;
	position: relative;
	width: 100vw;

	&__map {
		height: calc(100vh - #{$ruler-width * 2});
		left: $ruler-width;
		position: absolute;
		top: $ruler-width;
		width: calc(100vw - #{$ruler-width * 2});
	}

	&__textBlock {
		background-color: white;
		color: black;
		height: 24px;
		position: absolute;
		white-space: nowrap;
		width: 200px;
		z-index: 100;

		a {
			text-decoration: none;
		}
	}

	&__mousePosition {
		border-left: $ruler-border;
		border-bottom: $ruler-border;
		right: $ruler-width;
		top: $ruler-width;

		&Label {
			font-size: 14px;
			height: 23px;
			line-height: 23px !important;
			text-align: center;
			width: 100%;
		}
	}

	&__osmContributors {
		border-left: $ruler-border;
		border-top: $ruler-border;
		bottom: $ruler-width;
		font-size: 14px;
		line-height: 23px;
		right: $ruler-width;
		text-align: center;
	}
}